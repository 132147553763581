@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}

* {
  min-height: 0;
  min-width: 0;
}

/* Button.css */
.hmsui-button-iconOnlyLg svg {
  @apply h-9 w-auto;
}

.hmsui-button-iconOnlyMd svg {
  @apply h-6 w-auto;
}

.hmsui-button-iconOnlySm svg {
  @apply h-5 w-auto;
}

.hmsui-button-rootLg svg {
  @apply h-6 w-auto;
}

.hmsui-button-rootMd svg {
  @apply h-5 w-auto;
}

.hmsui-button-rootSm svg {
  @apply h-4 w-auto;
}

/* VideoTile/index.css */
.hmsui-videoTile-root:hover .hmsui-videoTileControls-controls {
  visibility: visible;
  max-height: 60px;
}

/* custom color toggle button */
input.custom-toggle[type="checkbox"] {
    height: 0;
    width: 0;
    padding-top: 0;
    visibility: hidden;
}

label.custom-toggle-label {
    cursor: pointer;
    text-indent: -9999px;
    width: 28px;
    height: 14px;
    background: #cccccc;
    opacity: 1;
    border-radius: 7px;
    display: block;
    position: relative;
}

label.custom-toggle-label:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-70%, -50%);
    width: 20px;
    height: 20px;
    background: #8f8f8f;
    border-radius: 50%;
    transition: 0.3s;
}

input.custom-toggle:checked + label.custom-toggle-label {
    background: #193a6e;
}

input.custom-toggle:checked + label.custom-toggle-label:after {
    top: 50%;
    left: 0%;
    transform: translate(70%, -50%);
    background: #2f80ff;
}
